import dayjs from "dayjs"
import AppDropdown from "./dropdown.vue"
import AppListCard from "./list-card.vue"
import eventbus from "../eventbus"

export default {
  name: "app-list",

  components: {
    AppDropdown,
    AppListCard,
  },

  props: [
    "period",
    "boards",
    "cards",
  ],

  data() {
    var start, stop
    switch (this.period) {
      case "overdue":
        start = dayjs("1970-01-01T00:00:00Z")
        stop = dayjs().add(-1, "day").endOf("day")
        break
      case "today":
        start = dayjs().startOf("day")
        stop = start.endOf("day")
        break
      case "tomorrow":
        start = dayjs().add(1, "day").startOf("day")
        stop = start.endOf("day")
        break
      case "thisweek":
        start = dayjs().add(2, "days").startOf("day")
        stop = start.endOf("isoweek")
        break
      case "nextweek":
        start = dayjs().add(1, "week").startOf("isoweek")
        stop = start.endOf("isoweek")
        break
      case "onemonth":
        start = dayjs().add(2, "weeks").startOf("isoWeek")
        stop = dayjs().add(30, "days").endOf("day")
        break
      case "threemonths":
        start = dayjs().add(31, "days").startOf("day")
        stop = dayjs().add(3, "months").endOf("month")
        break
      case "backlog":
        start = stop = null
        break
    }
    // console.debug(this.period, start && start.format(), stop && stop.format())

    return {
      start: start,
      stop: stop,
      dropCounter: 0,
      showMenu: false,
      sortName: "date_asc",
      sortFunc: null,
    }
  },

  computed: {
    date() {
      switch (this.period) {
        case "backlog":
          return ""
        case "thisweek":
        case "nextweek":
          return this.start.format("D") + "-" + this.stop.format("D MMM")
        case "overdue":
        case "today":
        case "tomorrow":
          return this.stop.format("D MMM")
        default:
          return this.stop.format("D MMM YYYY")
      }
    },

    visibleCards() {
      var start = this.start && this.start.unix()
      var stop = this.stop && this.stop.unix()

      function isVisible(card) {
        if (card.due) {
          if (start != null && stop != null) {
            // console.log(card.due, Math.floor(Date.parse(card.due) / 1000), start, stop)

            var due = Math.floor(Date.parse(card.due) / 1000)
            return due >= start && due <= stop
          }
        } else if (!start && !stop) {
          // backlog: cards without a due date
          return true
        }
      }

      return this.cards
        .filter(isVisible)
        .sort(this.sortFunc || this.orderByDueDateAsc)
    },
  },

  mounted() {
    window.addEventListener("keyup", (event) => {
      if (event.keyCode === 27) this.showMenu = false
    })
  },

  methods: {
    boardFor(card) {
      if (!card.board) {
        card.board = this.boards.find(b => b.id === card.idBoard)
      }
      return card.board
    },

    listFor(card) {
      if (!card.list) {
        var board = this.boardFor(card)
        if (board) {
          card.list = board.lists.find(l => l.id === card.idList)
        }
      }
      return card.list
    },

    orderBy(attribute) {
      switch (attribute) {
        case "date_asc": this.sortFunc = this.orderByDueDateAsc; break
        case "date_desc": this.sortFunc = this.orderByDueDateDesc; break
        case "board": this.sortFunc = this.orderByBoard; break
        case "list": this.sortFunc = this.orderByList; break
      }
      this.sortName = attribute
      this.showMenu = false
    },

    orderByDueDateAsc(a, b) {
      return this._compare(Date.parse(a.due), Date.parse(b.due))
    },

    orderByDueDateDesc(a, b) {
      return this._compare(Date.parse(b.due), Date.parse(a.due))
    },

    orderByBoard(a, b) {
      return this._compare(this.boardFor(a).name, this.boardFor(b).name)
    },

    orderByList(a, b) {
      return this._compare(this.listFor(a).name, this.listFor(b).name)
    },

    _compare(a, b) {
      return (a < b) ? -1 : ((a > b) ? 1 : 0)
    },

    ondragstart() {
      this.dropCounter = 0
    },

    ondragenter() {
      this.dropCounter += 1
    },

    ondragleave() {
      this.dropCounter -= 1
    },

    ondrop(event) {
      this.dropCounter = 0
      var id = event.dataTransfer.getData("text/plain")
      var card = this.cards.find(card => card.id === id)

      // don't update card that didn't change list:
      if (card.due && this.start && this.stop) {
        var curr = dayjs(card.due).unix()
        if (curr >= this.start.unix() && curr <= this.stop.unix()) return
      }

      if (this.stop) {
        // set card to be due on list stop date
        var date = this.stop.endOf("day")

        if (date.isoWeekday() > 5 && !["overdue", "today", "tomorrow"].includes(this.period)) {
          // clamp to previous Friday instead of selecting a Saturday or Sunday:
          date = date.isoWeekday(5)

          // still clamp to start date (just in case):
          if (this.start && this.start.unix() > date.unix()) {
            date = this.start
          }
        }
        this._updateCard(card, date.toJSON())
      } else {
        this._updateCard(card, null)
      }
    },

    _updateCard(card, due) {
      card.due = due

      Trello.put("/cards/" + card.id, { due: due },
        () => {},
        () => { eventbus.$emit("trello-error", { card, due }) }
      )
    },
  },
}
