/* eslint no-extend-native:off */

if (!Array.prototype.flatMap) {
  Object.defineProperties(Array.prototype, {
    flatMap: {
      value: function (lambda) {
        return Array.prototype.concat.apply([], this.map(lambda))
      },
      writeable: false,
      enumerable: false,
    }
  })
}
