export default {
  apiKey: "8402ec9a929564a71108fdb4568d0a0f",
  appName: "GPG PokerApp",

  locale: "fr",

  dealer: false,
  dealerToken: "a2f9ed6f6931165b",

  token: {
    scope: { read: "true", write: "true" },
    persist: true,
    expiration: "never",
  },

  getSessionItem(key, defaultValue) {
    if (this.dealer) key = `dealer:${key}`
    var value = sessionStorage.getItem(key)
    return value ? JSON.parse(value) : defaultValue
  },

  setSessionItem(key, value) {
    if (this.dealer) key = `dealer:${key}`
    sessionStorage.setItem(key, JSON.stringify(value))
  },

  removeSessionItem(key) {
    if (this.dealer) key = `dealer:${key}`
    sessionStorage.removeItem(key)
  },

  getLocalItem(key, defaultValue) {
    if (this.dealer) key = `dealer:${key}`
    var value = localStorage.getItem(key)
    return value ? JSON.parse(value) : defaultValue
  },

  setLocalItem(key, value) {
    if (this.dealer) key = `dealer:${key}`
    localStorage.setItem(key, JSON.stringify(value))
  },

  removeLocalItem(key) {
    if (this.dealer) key = `dealer:${key}`
    localStorage.removeItem(key)
  },
}
