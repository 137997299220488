export default {
  en: {
    main: {
      loading: {
        message: "Loading...",
      },
      dealer_login: {
        password_label: "Password:",
        button: "Connect",
      },
      login: {
        button: "Login with Trello",
      },
      header: {
        team: "Team",
        unselect_team: "Unselect Team",
        boards: "Boards",
        unselect_board: "Unselect Board",
        members: "Members",
        logout: "Logout",
      },
      drawer: {
        find_team_by_name: "Find team by name...",
        find_board_by_name: "Find board by name...",
        find_user_by_name: "Find user by name...",
        toggle_all: "Select/Unselect All",
      },
    },
    list: {
      headings: {
        overdue: "Overdue {date} ({count})",
        today: "Today {date} ({count})",
        tomorrow: "Demain {date} ({count})",
        thisweek: "This Week {date} ({count})",
        nextweek: "Next Week {date} ({count})",
        onemonth: "In 1 month {date} ({count})",
        threemonths: "In 3 Months {date} ({count})",
        backlog: "Backlog ({count})",
      },
      orders: {
        date_asc: "Order by Date Ascending",
        date_desc: "Order by Date Descending",
        board: "Order by Board",
        list: "Order by List",
      },
    },
    calendar: {
      months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      weekdays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
      dateLabel: "Date",
      timeLabel: "Time",
      nextMonth: "Next",
      prevMonth: "Prev",
      saveBtn: "Save",
      removeBtn: "Remove",
    },
    trello: {
      error: "Failed to save due date '{due}' for card '{name}'! Are you connected to the Internet?",
    },
  },

  fr: {
    main: {
      loading: {
        message: "Chargement en cours...",
      },
      dealer_login: {
        password_label: "Mot de passe :",
        button: "Connexion",
      },
      login: {
        button: "Se connecter avec Trello",
      },
      header: {
        team: "Équipe",
        unselect_team: "Désélectionner l'équipe",
        boards: "Tableau",
        unselect_board: "Déselectionner le tableau",
        members: "Membres",
        logout: "Déconnexion",
      },
      drawer: {
        find_team_by_name: "Filtrer la liste par nom...",
        find_board_by_name: "Filtrer la liste par nom...",
        find_member_by_name: "Filtrer la liste par nom...",
        toggle_all: "Tout sélectionner / désélectionner",
      },
    },
    list: {
      headings: {
        overdue: "En retard {date} ({count})",
        today: "Aujourd'hui {date} ({count})",
        tomorrow: "Demain {date} ({count})",
        thisweek: "Cette semaine {date} ({count})",
        nextweek: "Semaine prochaine {date} ({count})",
        onemonth: "Sous 1 mois {date} ({count})",
        threemonths: "Sous 3 mois {date} ({count})",
        backlog: "Backlog ({count})",
      },
      orders: {
        date_asc: "Trier par date croissante",
        date_desc: "Trier par date décroissante",
        board: "Trier par tableau",
        list: "Trier par liste",
      },
    },
    calendar: {
      months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
      weekdays: ["Lu", "Ma", "Me", "Je", "Ve", "Sa", "Di"],
      dateLabel: "Date",
      timeLabel: "Heure",
      nextMonth: "Suiv",
      prevMonth: "Préc",
      saveBtn: "Enregistrer",
      removeBtn: "Effacer",
    },
    trello: {
      error: "Impossible d'enregistrer la due date '{due}' pour la carte '{name}'! Êtes-vous connecté à Internet ?",
    },
  },
}
