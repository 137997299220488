import dayjs from "dayjs"
import MemberAvatar from "./avatar.vue"
import AppCalendar from "./calendar.vue"
import eventbus from "../eventbus"

export default {
  name: "app-list-card",

  components: {
    MemberAvatar,
    AppCalendar,
  },

  props: [
    "board",
    "list",
    "card",
  ],

  data() {
    return {
      showCalendar: false,
      calendarStyles: null,
    }
  },

  computed: {
    dueDate: {
      get() {
        return dayjs(this.card.due).format("YYYY-MM-DD")
      },

      set() {}
    },
  },

  methods: {
    format(dateString) {
      if (dateString) {
        return dayjs(dateString).format("ll")
      }
    },

    ondragstart(event) {
      event.dataTransfer.setData("text/plain", this.card.id)
      event.dataTransfer.dropEffect = "move"
      event.target.classList.add("is-dragging")
    },

    ondragend(event) {
      event.target.classList.remove("is-dragging")
    },

    openCard() {
      window.open(this.card.url)
    },

    saveDue(date) {
      this._updateCard(this.card, date)
      this.closeCalendar()
    },

    _updateCard(card, due) {
      card.due = due

      Trello.put("/cards/" + card.id, { due: due },
        () => {},
        () => { eventbus.$emit("trello-error", { card, due }) }
      )
    },

    openCalendar() {
      this.showCalendar = true

      // hardcoded width/height of the calendar popup:
      var w = 300; var h = 380

      // the width/height of the visible screen area:
      var sw = window.innerWidth; var sh = window.innerHeight

      // the card left/top coordinates, so we can place the popup near the card,
      // by default on the right-half of the card:
      var x = this.$el.offsetLeft; var y = this.$el.offsetTop
      x += this.$el.offsetWidth / 2

      // account for the lists horizontal scroll: by
      // FIXME: relies on a hardcoded CSS selector to the scrolled parent!
      x -= document.querySelector(".lists").scrollLeft

      // clamp the popup to the visible screen (with some margins):
      if ((x + w) > sw) x = Math.max(sw - w - 28, 0)
      if ((y + h) > sh) y = Math.max(sh - h - 28, 0)

      this.calendarStyles = {
        left: x + "px",
        top: y + "px",
      }
    },

    closeCalendar() {
      this.showCalendar = false
      this.calendarStyles = null
    },

    isOverdue(card) {
      return !!card.due &&
        dayjs(card.due).isBefore(dayjs(), "day")
    },

    isToday(card) {
      return !!card.due &&
        dayjs(card.due).isSame(dayjs(), "day")
    },
  },

  mounted() {
    this.escapeKeyHandler = (event) => {
      if (event.keyCode === 27) this.closeCalendar()
    }
    window.addEventListener("keyup", this.escapeKeyHandler)
  },

  beforeDestroy() {
    window.removeEventListener("keyup", this.escapeKeyHandler)
  },
}
