import Vue from "vue"
const eventbus = new Vue()

// Object.defineProperties(Vue.prototype, {
//   $bus: {
//     get() {
//       return eventbus;
//     },
//   },
// });

export default eventbus
