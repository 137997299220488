import Config from "../config"
import MemberAvatar from "./avatar.vue"
import AppList from "./list.vue"
import AppDrawer from "./drawer.vue"
import AppDropdown from "./dropdown.vue"
import dayjs from "dayjs"
import eventbus from "../eventbus"

require("../polyfills/array")
require("../directives/focus")

export default {
  name: "app",

  components: {
    MemberAvatar,
    AppDrawer,
    AppDropdown,
    AppList,
  },

  data() {
    return {
      state: "loading",
      loaded: false,

      dealer: Config.dealer,
      dealerToken: null,
      dealerTokenInvalid: null,

      me: null,
      organizations: [],
      boards: [],
      cards: [],
      members: [],

      selectedOrganization: null,
      selectedBoardIds: Config.getSessionItem("selectedBoardIds", null),
      selectedMemberIds: [],

      showDrawer: null,
      showUserMenu: false,

      notification: {
        message: null,
      },
    }
  },

  computed: {
    isDealerLogin() {
      return this.state === "dealer-login"
    },

    isLogin() {
      return this.state === "login"
    },

    isAuthenticated() {
      return this.state === "authenticated"
    },

    visibleBoards() {
      if (this.selectedOrganization) {
        return this.boards.filter(board => board.idOrganization === this.selectedOrganization.id)
      }
      return this.boards
    },

    visibleMembers() {
      return this.members
    },

    visibleCards() {
      var org; var cards = this.cards

      // filter cards from selected organization:
      if ((org = this.selectedOrganization)) {
        cards = cards.filter(card => org.idBoards.includes(card.idBoard))
      }

      // filter cards from selected boards:
      cards = cards.filter(card => this.selectedBoardIds.includes(card.idBoard))

      if (Config.dealer) {
        cards = cards.filter(c =>
          c.members.reduce((test, m) => test || this.selectedMemberIds.includes(m.id), false)
        )
      }

      return cards
    },
  },

  mounted() {
    var self = this
    eventbus.$on("trello-error", (ev) => self.onTrelloError(ev))

    if (Config.dealer && Config.getLocalItem("token") !== Config.dealerToken) {
      this.state = "dealer-login"
      return
    }

    Trello.authorize({
      interactive: false,
      name: Config.appName,
      scope: Config.token.scope,
      persist: Config.token.persist,
      expiration: Config.token.expiration,
      success: () => self.load(),
      error: () => self.logout(),
    })
  },

  methods: {
    authenticateDealer() {
      if (this.dealerToken === Config.dealerToken) {
        Config.setLocalItem("token", this.dealerToken)
        this.state = "login"
        this.authenticate()
      } else {
        this.dealerTokenInvalid = true
      }
    },

    authenticate() {
      Trello.authorize({
        type: "redirect",
        name: Config.appName,
        scope: Config.token.scope,
        persist: Config.token.persist,
        expiration: Config.token.expiration,
        success: () => this.load(),
        error: () => this.logout(),
      })
    },

    avatarUrl(url, size) {
      return url + "/" + size + ".png"
    },

    ready(user, organizations, boards, cards) {
      function byName(a, b) {
        return a.name.toLowerCase() > b.name.toLowerCase()
      }

      this.state = "authenticated"
      this.me = user
      this.organizations = organizations.sort(byName)
      this.boards = boards

      if (cards) {
        this._addCards(cards)
      }

      if (!this.selectedBoardIds) {
        this.selectedBoardIds = this.boards.map(b => b.id)
      }

      if (Config.dealer) {
        this._selectAllMembersFromSelectedBoards()
      }

      window.addEventListener("keyup", (event) => {
        if (event.keyCode === 27) this.closeDrawer()
      })
    },

    _addCards(cards) {
      // remove cards from closed boards and lists:
      var idBoards = this.boards.map(b => b.id)
      var idLists = this.boards.flatMap(b => b.lists.map(l => l.id))

      this.cards = this.cards.concat(cards
        .filter(c => idBoards.includes(c.idBoard))
        .filter(c => idLists.includes(c.idList))
        .filter(c => !c.dueComplete)
      )
    },

    _selectAllMembersFromSelectedBoards() {
      var memberIds = []
      var members = []

      this.boards.forEach(board => {
        if (this.selectedBoardIds.includes(board.id)) {
          board.members.forEach(member => {
            if (!memberIds.includes(member.id)) {
              memberIds.push(member.id)
              members.push(member)
            }
          })
        }
      })
      this.members = members.sort((a, b) => a.fullName.toLowerCase() > b.fullName.toLowerCase())
      this.selectedMemberIds = memberIds
    },

    logout(reload) {
      this.state = Config.dealer ? "dealer-login" : "login"
      this.me = null
      this.boards.length = 0
      this.cards.length = 0

      Config.removeLocalItem("token")
      Trello.deauthorize()

      if (reload) {
        window.location.reload()
      }
    },

    toggleDrawer(name) {
      var current = this.showDrawer
      this.showDrawer = null

      if (name !== current) {
        // wait for the DOM to have been re-rendered before switching drawers
        // (otherwise Vue will reuse the existing component, leading to issues):
        this.$nextTick(() => this.showDrawer = name)
      }
    },

    closeDrawer() {
      this.showDrawer = null
    },

    selectOrganization(organization) {
      this.selectedOrganization = organization
      this.closeDrawer()
    },

    isOrganizationSelected(org) {
      return this.selectedOrganization && this.selectedOrganization.id === org.id
    },

    toggleBoardSelection(board) {
      if (board) {
        var idx = this.selectedBoardIds.indexOf(board.id)
        if (idx === -1) {
          this.selectedBoardIds.push(board.id)
        } else {
          this.selectedBoardIds.splice(idx, 1)
        }
      } else {
        var visible = this.visibleBoards
        if (this.selectedBoardIds.length < visible.length) {
          this.selectedBoardIds = visible.map(b => b.id)
        } else {
          this.selectedBoardIds = []
        }
      }

      if (Config.dealer) {
        this._selectAllMembersFromSelectedBoards()
      }
      Config.setSessionItem("selectedBoardIds", this.selectedBoardIds)
    },

    isBoardSelected(board) {
      return this.selectedBoardIds.includes(board.id)
    },

    toggleMemberSelection(member) {
      if (member) {
        var idx = this.selectedMemberIds.indexOf(member.id)
        if (idx === -1) {
          this.selectedMemberIds.push(member.id)
        } else {
          this.selectedMemberIds.splice(idx, 1)
        }
      } else {
        var visible = this.visibleMembers
        if (this.selectedMemberIds.length < visible.length) {
          this.selectedMemberIds = visible.map(m => m.id)
        } else {
          this.selectedMemberIds = []
        }
      }

      Config.setSessionItem("selectedMemberIds", this.selectedMemberIds)
    },

    isMemberSelected(member) {
      return this.selectedMemberIds.includes(member.id)
    },

    load() {
      var onerror = () => this.logout()
      var urls

      if (Config.dealer) {
        // manager view: list all cards from all accessible boards:
        urls = [
          encodeURIComponent("/members/me"),
          encodeURIComponent("/members/me/organizations"),
          encodeURIComponent("/members/me/boards?filter=open&lists=open&members=all"),
        ]

        Trello.get("/batch?urls=" + urls.join(","), (response) => {
          var me = response[0][200]
          var organizations = response[1][200]
          var boards = response[2][200]

          var self = this
          var count = 0
          var len = boards.length

          function loadNextBatch() {
            var urls = []
            var start = count
            var stop = count += 10
            if (stop > len) stop = len

            for (var i = start; i < stop; i++) {
              urls.push(encodeURIComponent("/boards/" + boards[i].id + "/cards?filter=open&members=true"))
            }

            Trello.get("/batch?urls=" + urls.join(","), (responses) => {
              var cards = []

              responses.forEach(response => {
                if (response[200]) {
                  response[200].forEach(card => cards.push(card))
                }
              })
              self._addCards(cards)

              if (count < len) {
                setTimeout(loadNextBatch, 250)
              } else {
                self.loaded = true
              }
            }, onerror)
          }

          if (boards.length) {
            setTimeout(loadNextBatch, 250)
            this.ready(me, organizations, boards, null)
          } else {
            this.ready(me, organizations, boards, null)
            this.loaded = true
          }
        }, onerror)
      } else {
        // collaborator view: list cards whose the user is a member:
        urls = [
          encodeURIComponent("/members/me"),
          encodeURIComponent("/members/me/organizations"),
          encodeURIComponent("/members/me/boards?filter=open&lists=open"),
          encodeURIComponent("/members/me/cards?filter=open&members=true"),
        ]

        Trello.get("/batch?urls=" + urls.join(","), (response) => {
          this.ready(response[0][200], response[1][200], response[2][200], response[3][200])
          this.loaded = true
        }, onerror)
      }
    },

    onTrelloError(ev) {
      this.notification.message = this.$t("trello.error", {
        name: ev.card.name,
        due: dayjs(ev.due).format("lll"),
      })
      setTimeout(() => this.notification.message = null, 10000)

      if (window.console) {
        window.console.error("Failed to save card to trello: ", { id: ev.card.id, name: ev.card.name, due: ev.due })
      }
    },
  },
}
