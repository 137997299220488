require("../directives/focus")

export default {
  name: "app-drawer",

  props: [
    "collection",
    "label",
    "onselect",
    "isSelected",
    "placeholder",
    "showToggleButton",
  ],

  data() {
    return {
      filtered: null,
      search: null,
      timer: null,
    }
  },

  mounted() {
    this.filter()
  },

  methods: {
    filter() {
      var value = this.search

      if (value && value.length) {
        value = value.toLowerCase()
        this.filtered = this.collection.filter(o => o[this.label].toLowerCase().includes(value))
      } else {
        this.filtered = this.collection
      }
    },

    styleFor(entry, type) {
      var styles = {}

      if (entry.prefs) {
        // teams, boards
        var scaled = entry.prefs.backgroundImageScaled
        if (scaled) {
          styles["background-image"] = "url('" + scaled[2].url + "')"
        } else {
          styles["background-color"] = entry.prefs.backgroundColor
        }
      } else if (type === "icon" && entry.avatarUrl) {
        // member
        styles["background-image"] = "url('" + entry.avatarUrl + "/50.png')"
      }

      return styles
    },

    armCloseHandler() {
      this.timer = setTimeout(() => this.$emit("focusout"))
    },

    disarmCloseHandler() {
      if (this.timer) clearTimeout(this.timer)
    },
  },
}
