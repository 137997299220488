require("../directives/focus")

export default {
  name: "app-dropdown",

  methods: {
    armCloseHandler() {
      this.timer = setTimeout(() => this.$emit("focusout"))
    },

    disarmCloseHandler() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
    },
  },

  // mounted() {
  //   this.escapeKeyHandler = () => this.$emit("focusout");
  //   window.addEventListener("keyup", this.escapeKeyHandler);
  // },

  // beforeDestroy() {
  //   window.removeEventListener("keyup", this.escapeKeyHandler);
  // },
}
