export default {
  name: "member-avatar",

  props: ["member", "size"],

  computed: {
    url() {
      return this.member.avatarUrl + "/50.png"
    },
  },
}
