import dayjs from "dayjs"
require("../directives/focus")

export default {
  name: "app-calendar",

  props: ["value"],

  data() {
    var today = dayjs()
    var year = today.year()

    // TODO: get value from props
    var value = this.value ? dayjs(this.value) : dayjs()

    return {
      // the selected date & time:
      date: value.format("DD/MM/YYYY"),
      time: value.format("HH:mm"),
      mdate: value,

      // the currently displayed month:
      current: dayjs(),

      // reminder of today:
      today: today,
      month: today.month(),
      year: year,

      // years to display:
      years: [
        year - 1,
        year,
        year + 1,
        year + 2,
        year + 3,
      ],
    }
  },

  watch: {
    date(value, oldValue) {
      if (value === oldValue) return
      var date = dayjs(value, "DD/MM/YYYY")
      this.mdate = date
    },

    month(value) {
      this.current = this.current.month(value)
    },

    year(value) {
      this.current = this.current.year(value)
    },
  },

  methods: {
    prevMonth() {
      this.current = this.current.add(-1, "month")
      this.month = this.current.month()
      this.year = this.current.year()
    },

    nextMonth() {
      this.current = this.current.add(1, "month")
      this.month = this.current.month()
      this.year = this.current.year()
    },

    weeks() {
      var firstDayOfMonth = this.current.startOf("month")
      var firstWeekday = firstDayOfMonth.startOf("isoweek")

      var lastDayOfMonth = this.current.endOf("month")
      var lastWeekday = lastDayOfMonth.endOf("isoweek")

      var day = firstWeekday
      var weeks = []

      while (day.isBefore(lastWeekday)) {
        var week = []
        weeks.push(week)

        for (var wd = 1; wd < 8; wd++) {
          week.push(day)
          day = day.add(1, "day")
        }
      }

      return weeks
    },

    select(day) {
      this.date = day.format("DD/MM/YYYY")
    },

    save() {
      var date = this.mdate
      var time = this.time.split(":")
      var datetime = date
        .hour(time[0])
        .minute(time[1])
        .second(time[2] || 0)
        .millisecond(0)
      this.$emit("save", datetime.toJSON())
    },

    remove() {
      this.$emit("remove")
    },

    armCloseHandler() {
      this.timer = setTimeout(() => this.$emit("focusout"))
    },

    disarmCloseHandler() {
      if (this.timer) clearTimeout(this.timer)
    },
  },

  mounted() {
    this.escapeKeyHandler = (event) => {
      if (event.keyCode === 27) this.showCalendar = false
    }
    window.addEventListener("keyup", this.escapeKeyHandler)
  },

  beforeDestroy() {
    window.removeEventListener("keyup", this.escapeKeyHandler)
  },
}
